@import '~antd/dist/antd.css';

.toastui-editor-md-code, .toastui-editor-md-code-block {
    font-family: Consolas;
}
.toastui-editor-contents code, .toastui-editor-contents pre {
    font-family: Consolas;
}
.toastui-editor-contents {
    font-family: Microsoft Yahei;
}
.ProseMirror {
    font-family: Microsoft Yahei;
}